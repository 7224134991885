import { Injectable } from '@angular/core';
import {
  GetTrialBindersResults,
  TrialBinderDocument,
  TrialBinderFolderModel, TrialBinderFolderType
} from '../../../types/api/models/data-contracts';
import {BehaviorSubject, Observable, of, ReplaySubject, shareReplay, Subject} from 'rxjs';

export interface ITrialBinderStateService {
  trialBinders$: Observable<GetTrialBindersResults[]>;
  selectedTrialBinder$: Observable<GetTrialBindersResults>;
  selectedTrialBinderFolderId$: Observable<string>;
  selectedTrialBinderArchiveFolderId$: Observable<string>;
  selectedFolder$: Observable<TrialBinderFolderModel>;
  selectedDocuments$: Observable<TrialBinderDocument[]>;
  trialBinderFolders$: Observable<TrialBinderFolderModel[]>;
  trialBinderFolderDocuments$: Observable<TrialBinderDocument[]>;
  isFolderViewLoading$: Observable<boolean>;
  isRootActive$: Observable<boolean>;
  updateDocuments$: Observable<void>;
  updateFolders$: Observable<void>;
  deleteFolder$: Observable<TrialBinderFolderModel>;
  errorMessage$: Observable<string>;

  setTrialBinders(trialBinders: GetTrialBindersResults[]): void;
  setSelectedTrialBinder(trialBinder: GetTrialBindersResults): void;
  setSelectedTrialBinderFolderId(trialBinderFolderId: string): void;
  setSelectedTrialBinderArchiveFolderId(trialBinderArchiveFolderId: string): void;
  setSelectedTrialBinderFolder(trialBinderFolder: TrialBinderFolderModel): void;
  setSelectedDocuments(documents: TrialBinderDocument[]): void;
  setTrialBinderFolders(trialBinderFolders: TrialBinderFolderModel[]): void;
  setTrialBinderFolderDocuments(trialBinderFolderDocuments: TrialBinderDocument[]): void;
  isFolderViewLoading(isLoading: boolean): void;
  isRootActive(isActive: boolean): void;
  updateDocuments(): void;
  updateFolders(): void;
  deleteFolder(folder: TrialBinderFolderModel): void;
  showError(error: string): void;
}
@Injectable({
  providedIn: 'root'
})
export class TrialBindersStateService implements ITrialBinderStateService {
  private trialBinders$$ = new BehaviorSubject<GetTrialBindersResults[]>([]);
  trialBinders$: Observable<GetTrialBindersResults[]> = this.trialBinders$$.asObservable();

  private selectedTrialBinder$$ = new BehaviorSubject<GetTrialBindersResults>({} as GetTrialBindersResults);
  selectedTrialBinder$: Observable<GetTrialBindersResults> = this.selectedTrialBinder$$.asObservable();

  private selectedTrialBinderFolderId$$ = new BehaviorSubject<string>(null);
  selectedTrialBinderFolderId$: Observable<string> = this.selectedTrialBinderFolderId$$.asObservable();

  private selectedTrialBinderArchiveFolderId$$ = new BehaviorSubject<string>(null);
  selectedTrialBinderArchiveFolderId$: Observable<string> = this.selectedTrialBinderArchiveFolderId$$.asObservable();

  private selectedTrialBinderFolder$$ = new ReplaySubject<TrialBinderFolderModel>(3);
  selectedFolder$: Observable<TrialBinderFolderModel> = this.selectedTrialBinderFolder$$.asObservable();

  private selectedDocuments$$ = new BehaviorSubject<TrialBinderDocument[]>([]);
  selectedDocuments$: Observable<TrialBinderDocument[]> = this.selectedDocuments$$.asObservable();

  private trialBinderFolders$$ = new BehaviorSubject<TrialBinderFolderModel[]>([]);
  trialBinderFolders$: Observable<TrialBinderFolderModel[]> = this.trialBinderFolders$$.asObservable();

  private trialBinderFolderDocuments$$ = new BehaviorSubject<TrialBinderDocument[]>([]);
  trialBinderFolderDocuments$: Observable<TrialBinderDocument[]> = this.trialBinderFolderDocuments$$.asObservable();

  private updateFolders$$ = new BehaviorSubject<void>(null);
  updateFolders$: Observable<void> = this.updateFolders$$.asObservable().pipe(shareReplay(1));

  private updateDocuments$$ = new BehaviorSubject<void>(null);
  updateDocuments$: Observable<void> = this.updateDocuments$$.asObservable().pipe(shareReplay(1));

  private isFolderViewLoading$$ = new BehaviorSubject<boolean>(false);
  isFolderViewLoading$: Observable<boolean> = this.isFolderViewLoading$$.asObservable();

  private isRootActive$$ = new BehaviorSubject<boolean>(true);
  isRootActive$: Observable<boolean> = this.isRootActive$$.asObservable();

  private deleteFolder$$ = new BehaviorSubject<TrialBinderFolderModel>(null);
  deleteFolder$: Observable<TrialBinderFolderModel> = this.deleteFolder$$.asObservable();

  private error$$ = new Subject<string>();
  errorMessage$: Observable<string> = this.error$$.asObservable();

  setTrialBinders(trialBinders: GetTrialBindersResults[]) {
    this.trialBinders$$.next(trialBinders);
  }

  setSelectedTrialBinder(trialBinder: GetTrialBindersResults) {
    this.selectedTrialBinder$$.next(trialBinder);
  }

  setSelectedTrialBinderFolderId(trialBinderFolderId: string) {
    this.selectedTrialBinderFolderId$$.next(trialBinderFolderId);
  }

  setSelectedTrialBinderArchiveFolderId(trialBinderArchiveFolderId: string) {
    this.selectedTrialBinderArchiveFolderId$$.next(trialBinderArchiveFolderId);
  }

  setSelectedTrialBinderFolder(trialBinderFolder: TrialBinderFolderModel) {
    this.selectedTrialBinderFolder$$.next(trialBinderFolder);
  }

  setTrialBinderFolders(trialBinderFolders: TrialBinderFolderModel[]) {
    const sorted = trialBinderFolders.sort((a, b) => {
      return a.folderType - b.folderType;
    });
    const archiveFolder = sorted.find(f => f.folderType === TrialBinderFolderType.Value2);
    if (!!archiveFolder) {
      this.setSelectedTrialBinderArchiveFolderId(archiveFolder.folderId);
    }
    this.trialBinderFolders$$.next(sorted);
  }

  setTrialBinderFolderDocuments(trialBinderFolderDocuments: TrialBinderDocument[]) {
    this.trialBinderFolderDocuments$$.next(trialBinderFolderDocuments);
  }

  setSelectedDocuments(documents: TrialBinderDocument[]) {
    this.selectedDocuments$$.next(documents);
  }

  isRootActive(isActive: boolean) {
    this.isRootActive$$.next(isActive);
  }

  isFolderViewLoading(isLoading: boolean) {
    this.isFolderViewLoading$$.next(isLoading);
  }

  updateDocuments() {
    this.updateDocuments$$.next();
  }

  updateFolders() {
    this.updateFolders$$.next();
  }

  deleteFolder(folder: TrialBinderFolderModel) {
    this.deleteFolder$$.next(folder);
  }

  showError(error: string) {
    this.error$$.next(error);
  }
}
