export enum ENVIRONMENT {
  DEV = 'dev',
  UAT = 'uat',
  PROD = 'production',
}

export interface EnvConfig {
  ApiServer: {
    BaseURL: string;
  };
  API_VERSION: string;
  CONFIG_FILE: ENVIRONMENT;
  Environment: {
    Env: string;
  };
  IdentityServer: {
    BaseURL: string;
  };
  iframeUrl: string;
  filingUrl: string;
  MAX_FILE_SIZE: number;
  production: boolean;
}
