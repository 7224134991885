import { bootstrapApplication } from '@angular/platform-browser';
import { WINDOW, windowFactory } from './app/core/window.factory';
import { AppComponent, loadConfig } from './app/app.component';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {provideRouter} from '@angular/router';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { FSXInterceptor } from './app/core/fsx.interceptor';
import {
  AppConfig,
  ENV_CONFIG,
} from './app/core/app-config/app-config.service';
import { Observable } from 'rxjs';
import {
  APP_INITIALIZER,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnvConfig } from './app/types/environments';
import { environment } from './environments/environment';
import { APP_ROUTES } from './routes';
import { MatDialogModule } from '@angular/material/dialog';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: FSXInterceptor, multi: true },
    { provide: WINDOW, useFactory: windowFactory },
    {
      provide: ENV_CONFIG,
      useFactory: (appConfig: AppConfig): Observable<EnvConfig> =>
        appConfig.envConfig,
      deps: [AppConfig],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [AppConfig],
      multi: true,
    },
    provideOAuthClient(),
    provideRouter(APP_ROUTES),
    importProvidersFrom(BrowserAnimationsModule, MatDialogModule),
  ],
}).catch(err => console.error('Error occurred in bootstrapApplication:', err));
