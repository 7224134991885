import { FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseItem, FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseRequest, FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseResult,
} from '../../../types/api/dot-net-api-types/dev-api/data-contracts';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { courtHousesEndpoints } from '../../../types/api/endpoints/court-houses.endpoints';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface ICourtHousesApiService {
  getCourtHouses(stateCode: string): Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseItem[]>;
  createCourtHouse(courtHouse: FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseRequest): Observable<string>;
  getCourtHouseById(courtHouseId: string): Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseResult>;
  updateCourtHouse(courtHouseId: string, name: string, stateCode: string): Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseRequest>;
  deleteCourtHouse(courtHouseId: string): Observable<string>;
  getCourtHouseStates(): Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseItem[]>;
}
/**
 * Base url for the api.
 */
const BASE_URL = environment.ApiServer.BaseURL;

/**
 * Court House Endpoints for the api.
 */
const COURT_HOUSE_ENDPOINTS = courtHousesEndpoints(BASE_URL);

@Injectable({
  providedIn: 'root'
})
export class CourtHousesApiService implements ICourtHousesApiService {

  constructor(private readonly http: HttpClient) {
  }

  /**
   * Endpoint to get court houses by state code
   * @param stateCode
   */
  getCourtHouses(stateCode: string): Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseItem[]> {
    const url = COURT_HOUSE_ENDPOINTS.getCourtHouses(stateCode);
    return <Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseItem[]>>this.http.get(url);
  }

  /**
   * Endpoint to create a court house
   * @param courtHouse
   */
  createCourtHouse(courtHouse: FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseRequest): Observable<string> {
    const url = COURT_HOUSE_ENDPOINTS.createCourtHouse();
    return <Observable<string>>this.http.post(url, courtHouse);
  }

  /**
   * Endpoint to get a court house by id
   * @param courtHouseId
   */
  getCourtHouseById(courtHouseId: string): Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseResult> {
    const url = COURT_HOUSE_ENDPOINTS.getCourtHouseById(courtHouseId);
    return <Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseResult>>this.http.get(url);
  }

  /**
   * Endpoint to update a court house
   * @param courtHouseId
   * @param params
   */
  updateCourtHouse(courtHouseId: string, requestBody: any): Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseRequest> {
    const url = COURT_HOUSE_ENDPOINTS.updateCourtHouse(courtHouseId); // Use the base endpoint
    return <Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseRequest>>this.http.put(url, requestBody);
  }

  /**
   * Endpoint to delete a court house
   * @param courtHouseId
   */
  deleteCourtHouse(courtHouseId: string): Observable<string> {
    const url = COURT_HOUSE_ENDPOINTS.deleteCourtHouse(courtHouseId);
    return <Observable<string>>this.http.delete(url);
  }

  /**
   * Endpoint to get court house states
   */
  getCourtHouseStates(): Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseItem[]> {
    const url = COURT_HOUSE_ENDPOINTS.getCourtHouseStates();
    return <Observable<FileAndServeXpressModernWebPublicAPIModelsCourtHouseCourtHouseItem[]>>this.http.get(url);
  }

  private buildUrlWithParams(baseUrl: string, params: any): string {
    const queryParams = Object.keys(params)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
    return `${baseUrl}?${queryParams}`;
  }
}
