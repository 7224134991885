import {FSXEndpoints} from './endpoints.model';
import {trialBinderEndpoints} from './trial-binder.endpoints';
import {caseManagementEndpoints} from './case-management.endpoints';

export function fsxApi(baseURL: string): FSXEndpoints {
  const versionedUrl = `${baseURL}`;

  return {
    trialBinder: trialBinderEndpoints(versionedUrl),
    caseManagement: caseManagementEndpoints(versionedUrl)
  };
}
