<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side" opened fixedInViewport="Fixed">
    <app-navbar></app-navbar>
  </mat-sidenav>
  <mat-sidenav-content>
    <main>
      <router-outlet></router-outlet>
      <router-outlet name="sidepanel-info"></router-outlet>
    </main>
    <footer>
      <strong>Powered by File & ServeXpress</strong> &#169;
      {{ currentDate | date : 'yyyy' }} All rights reserved.
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
