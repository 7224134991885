import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../core/auth/auth.service';
import { RouterLink, RouterOutlet, RouterLinkActive } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutoOpenMenuComponent } from '../auto-open-menu/auto-open-menu.component';
import { menu } from './menu';
import { NavItem } from './navbar.types';
import {TrialBindersStateService} from '../../trialbinder/services/ui-state/trial-binders-state.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterOutlet,
    RouterLinkActive,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    AutoOpenMenuComponent,
  ],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  navLinks: NavItem[] = menu;

  constructor(private authService: AuthService,
              private readonly trialBinderStateService: TrialBindersStateService) {}

  logout() {
    this.authService.logout();
  }

  /**
   * Resets the trial binders states.
   */
  resetTrialBinders(): void {
    this.trialBinderStateService.isRootActive(true);
    this.trialBinderStateService.setSelectedTrialBinderFolder(null);
  }
}
