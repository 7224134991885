/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
export interface AdditionalRecipient {
  deliveryName?: string | null;
  deliveryAddress?: string | null;
}

export interface CourtBase {
  /** @format int32 */
  id?: number;
  name?: string | null;
  state?: string | null;
}

export interface FilingDetailDocument {
  leadDocument?: boolean;
  /** @format int32 */
  numberOfPages?: number | null;
  /** @format int64 */
  documentId?: number;
  documentType?: string | null;
  documentSecurity?: string | null;
  /** @format double */
  statutoryFee?: number;
  documentTitle?: string | null;
  clerkReviewStatus?: string | null;
  /** @format date-time */
  reviewDate?: string | null;
}

export interface FilingDetails {
  /** @format int64 */
  filingId?: number;
  caseNumber?: string | null;
  caseName?: string | null;
  courtName?: string | null;
  division?: string | null;
  /** @format date-time */
  authorizedDate?: string | null;
  authorizor?: string | null;
  authorizerOrganization?: string | null;
  sender?: string | null;
  senderOrganization?: string | null;
  caseClass?: string | null;
  caseType?: string | null;
  filingOption?: string | null;
  billingReference?: string | null;
  documents?: FilingDetailDocument[] | null;
  sendingParties?: FilingParty[] | null;
  serviceRecipients?: ServiceRecipient[] | null;
  additionalRecipients?: AdditionalRecipient[] | null;
}

export interface FilingParty {
  partyName?: string | null;
  partyType?: string | null;
  attorneyName?: string | null;
  attorneyType?: string | null;
  firmName?: string | null;
}

export interface GetTrialBinderCaseDocuments {
  documents?: GetTrialBinderCaseDocumentsResults[] | null;
  filingTypes?: string[] | null;
}

export interface GetTrialBinderCaseDocumentsResults {
  /** @format int32 */
  documentID?: number;
  documentTitle?: string | null;
  filingType?: string | null;
  /** @format int32 */
  filingID?: number;
  partyType?: string | null;
  partyName?: string | null;
  attorneyFirm?: string | null;
  isMyFirm?: boolean;
  isMe?: boolean;
}

export interface GetTrialBindersResults {
  /** @format uuid */
  id?: string;
  system?: string | null;
  caseName?: string | null;
  caseNumber?: string | null;
  courtName?: string | null;
  isBookmarked?: boolean;
}

export interface MoveDocumentModel {
  /** @format uuid */
  fromFolderId?: string;
  /** @format uuid */
  toFolderId?: string | null;
  documentIds?: string[] | null;
}

export interface NewFolderModel {
  /** @format uuid */
  parentId?: string | null;
  folderName?: string | null;
}

export interface NewRegistration {
  organization?: OSOrganization;
  primaryContact?: OSContact;
  billingContact?: OSContact;
  contactType?: OSContactType;
  authorizer?: OSAuthorizer;
}

export interface OSAuthorizer {
  name?: string | null;
  title?: string | null;
}

export interface OSContact {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  suffixName?: string | null;
  email?: string | null;
  phone?: string | null;
  phoneExtension?: string | null;
}

/** @format int32 */
export enum OSContactType {
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface OSOrganization {
  orgType?: OrganizationType;
  name?: string | null;
  address?: string | null;
  country?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

/** @format int32 */
export enum OrganizationType {
  Value1 = 1,
  Value4 = 4,
  Value5 = 5,
  Value6 = 6,
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface SearchDocumentResult {
  parentFolder?: string | null;
  /** @format uuid */
  documentId?: string;
  /** @format uuid */
  folderId?: string;
  documentTitle?: string | null;
  documentType?: string | null;
  fileName?: string | null;
  systemDocumentId?: string | null;
  transactionId?: string | null;
}

export interface ServiceRecipient {
  party?: FilingParty;
  deliveryStatus?: string | null;
  deliveryMethod?: string | null;
  deliveryType?: string | null;
}

/** @format int32 */
export enum ThreadUsersFilter {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
  Value4 = 4,
}

export interface TrialBinderDocument {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  folderId?: string;
  title?: string | null;
  transactionId?: string | null;
  fileName?: string | null;
  partyType?: string | null;
  partyName?: string | null;
  accessType?: string | null;
  documentType?: string | null;
  documentLocation?: string | null;
  /** @format int32 */
  documentSize?: number;
  /** @format date-time */
  dateCreated?: string;
  systemDocumentId?: string | null;
  createdBy?: string | null;
  archivedBy?: string | null;
  archivedDate?: string | null;
  originalPath?: string | null;
}

export interface TrialBinderFolderModel {
  folderType?: TrialBinderFolderType;
  folderName?: string | null;
  children?: TrialBinderFolderModel[] | null;
  /** @format uuid */
  folderId?: string | null;
  /** @format int32 */
  documentCount?: number;
  isSystemFolder?: boolean;
  /** @format int64 */
  totalSize?: number;
  /** @format date-time */
  createdOn?: string;
  /** @format date-time */
  lastModified?: string;
  /** @format: string */
  createdBy?: string | null;
}

/** @format int32 */
export enum TrialBinderFolderType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}
