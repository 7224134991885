import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import {FsxB2CDocumentDiscoveryURL} from './auth/auth-config';

@Injectable({
  providedIn: 'root',
})
export class FSXInterceptor implements HttpInterceptor {

  public constructor(private readonly oAuthService: OAuthService) {}

  // https://github.com/johnpapa/http-interceptors/tree/main/angular-app/src/app/core/interceptors
  // https://danielk.tech/home/angular-retry-an-http-request
  // https://www.tektutorialshub.com/angular/retry-retrywhen-in-angular-observable/#adding-a-delay

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const authToken = this.oAuthService.getAccessToken() ?? null;
    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${authToken}`),
    });

    return next.handle(request).pipe(
      catchError((httpError: HttpErrorResponse) => {
        if (httpError.status === 401) {
          return this.retryLogin(request, next);
        }
        return throwError(httpError);
      }),
    );
  }

  private retryLogin(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return from(this.oAuthService.loadDiscoveryDocument(FsxB2CDocumentDiscoveryURL)).pipe(
      switchMap(() => next.handle(request)),
      take(1),
    );
  }
}
