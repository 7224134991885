import { ENVIRONMENT, EnvConfig } from '../app/types/environments';

const BaseURL = 'https://fsx-modern.dev.fileandserve.com/';
const BaseIdentityServerURL = 'https://login.dev.fileandserve.com/fileandservexpressuat.onmicrosoft.com/B2C_1A_DEMO_SUSI_USERNAME_SEAMLESSMIGRATION/v2.0';

export const environment: EnvConfig = {
  ApiServer: { BaseURL },
  API_VERSION: 'v1',
  CONFIG_FILE: ENVIRONMENT.DEV,
  Environment: {
    Env: ENVIRONMENT.DEV,
  },
  IdentityServer: {
    BaseURL: BaseIdentityServerURL,
  },
  iframeUrl: 'https://www-dev01.dev.fileandserve.com/Login/Login.aspx?oauth=true',
  filingUrl: 'https://app.efsp.dev.fileandserve.com/',
  MAX_FILE_SIZE: 1_000_000,
  production: false,
};
