/* Create an factory function that returns the native window object. */
import { isPlatformBrowser } from '@angular/common';
import { InjectionToken } from '@angular/core';
import { BrowserWindowRef } from './browser-window.util';

export const WINDOW = new InjectionToken('WindowToken');

export function windowFactory(
  browserWindowRef: BrowserWindowRef,
  platformId: { [key: string]: number },
): Window {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }
  return {} as Window;
}
