import { NavItem } from './navbar.types';

export let menu: NavItem[] = [
    {
      route: 'home',
      title: 'Home',
      svgIcon: 'home',
      classes: '',
    },
    /*{
      route: 'filing',
      title: 'FSLA',
      svgIcon: 'filing',
      classes: '',
    }, */
    {
      route: 'trialbinder',
      title: 'eBinder',
      svgIcon: 'folder_open',
      classes: '',
    },
    /*{
      route: 'alerts',
      title: 'Alerts',
      svgIcon: 'alert',
      classes: '',
    },
    {
      route: 'search',
      title: 'Search',
      svgIcon: 'search',
      classes: '',
    },*/
    {
      route: 'messageboard',
      title: 'Message Board',
      svgIcon: 'comment',
      classes: '',
    },
  ];
