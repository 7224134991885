import {Component, Inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {FlexModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

export interface SnackbarData {
  snackbar: MatSnackBar;
  message: string;
}

@Component({
  selector: 'app-snackbar',
  standalone: true,
  imports: [CommonModule, FlexModule, MatButtonModule, MatIconModule],
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  /**
   * The message to display in the snackbar.
   */
  message!: string;

  /**
   * Constructor for the snackbar
   * @param data The data injected from the snackbar service.
   */
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData) {}

  /**
   * Angular lifecycle hook.
   * Called after the constructor, initializing input properties.
   */
  ngOnInit() {
    this.message = this.data.message;
  }

  /**
   * Event handler for when the close button is clicked.
   */
  onCloseSnackbarClicked(): void {
    this.data.snackbar.dismiss();
  }
}
