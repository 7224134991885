import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface ISuccessStateService {
  successMessage$: Observable<string>;
  showSuccess(message: string): void;
}

@Injectable({
  providedIn: 'root'
})
export class SuccessStateService implements ISuccessStateService {

  constructor() { }

  private success$$ = new Subject<string>();
  successMessage$: Observable<string> = this.success$$.asObservable();


  showSuccess(message: string) {
    this.success$$.next(message);
  }
}
