export interface ICaseManagementEndpoints {
  getCourtInfo: (courtId: number) => string;
  getMaintenanceActions: (courtId: number) => string;
  getCourts: () => string;
  caseSearch: (courtId: number) => string;
  caseSearchByCaseClassId: (courtId: number, caseClassId: number) => string;
  partySearch: (term: string) => string;
  getParticipants: (courtId: number) => string;
  getAttorneys: (courtId: number) => string;
  getCase: (courtId: number, caseId: number) => string;
  applyOrQueueNewCase: (courtId: number) => string;
  applyOrQueueCaseUpdate: (courtId: number, caseId: number) => string;
  updateNewQueueCase: (courtId: number, entryId: number) => string;
  getParticipantsByCourt: (courtId: number) => string;
  getParticipantsByCase: (courtId: number, caseId: number) => string;
  getParticipantsByAttorney: (courtId: number, attorneyId: number) => string;
  getParticipantDetail: (courtId: number, caseId: number, partyId: number, partyTypeId: number) => string;
  getCourtPersonnel: (courtId: number, term: string) => string;
  getCourtPersonnelByCase: (courtId: number, caseId: number) => string;
  getCasesByPersonnel: (courtId: number, personId: number) => string;
  getCaseStatusCatalog: (courtId: number) => string;
  getCaseClassCatalog: (courtId: number) => string;
  getCaseTypeCatalog: (courtId: number, caseClassId: number) => string;
  getDivisionCatalog: (courtId: number) => string;
  getJudgeCatalog: (courtId: number) => string;
  searchJudgeCatalog: (courtId: number) => string;
  getClerkCatalog: (courtId: number) => string;
  searchClerkCatalog: (courtId: number) => string;
  getAttorneyTypeCatalog: (courtId: number) => string;
  getCourtCaseClassInfo: (courtId: number, caseClassId: number) => string;
  getCaseManagementCatalogs: (courtId: number, catalogs?: string[]) => string;
  attorneySearch: (courtId: number, term: string, searchInMyFirm: boolean) => string;
  addJudgesClerksToCourtCatalog: (courtId: number) => string;
  getLowerCourts: (courtId: number) => string;
  tempDocuments: string;
  appealableCaseSearch: (courtId: number) => string;
  caseGroupSearch: (courtId: number) => string;
  getCaseGroup: (courtId: number, groupId: number) => string;
  caseSearchByCaseGroup: (courtId: number, groupId: number) => string;
  addCaseGroup: (courtId: number) => string;
  deleteCaseGroup: (courtId: number, groupId: number) => string;
  removeCaseFromCaseGroup: (courtId: number, groupId: number) => string;
  addCasesToCaseGroup: (courtId: number, groupId: number) => string;

  getQueueCaseList: (courtId: number) => string;
  getQueueAttorneyList: (courtId: number) => string;
  getQueueParticipantList: (courtId: number) => string;
  getQueueParticipantEntry: (courtId: number, entryId: number) => string;
  getQueueCourtPersonnelList: (courtId: number) => string;

  approveQueueCaseList: (courtId: number) => string;
  approveQueueAttorneyList: (courtId: number) => string;
  approveQueueParticipantList: (courtId: number) => string;
  approveQueueCourtPersonnelList: (courtId: number) => string;

  getQueueCaseEntry: (courtId: number, entryId: number) => string;
  getQueueCourtPersonnelEntry: (courtId: number, entryId: number) => string;
  getQueueAttorneyEntry: (courtId: number, entryId: number) => string;

  getCaseLoadList: (courtId: number, uploadType: string) => string;
}

export const caseManagementEndpoints = (versionedUrl: string): ICaseManagementEndpoints => {
  const courtControllerURL = `${versionedUrl}/courts`;
  const courtController_CourtIdURL = (courtId: number) =>`${versionedUrl}/courts/${courtId}`;
  const caseControllerURL = (courtId: number) => `${versionedUrl}/courts/${courtId}/cases`;
  const documentsControllerURL = `${versionedUrl}/documents`;

  return {
    getCourtInfo: (courtId: number) => `${courtControllerURL}/${courtId}`,
    getMaintenanceActions: (courtId: number) => `${courtControllerURL}/${courtId}/maintenance-actions`,
    getCourts: () => `${courtControllerURL}/allow-maintenance`,
    partySearch: (term: string) => `${versionedUrl}/parties?term=${term}`,
    getParticipants: (courtId: number) => `${courtControllerURL}/${courtId}/participants`,
    getAttorneys: (courtId: number) => `${courtControllerURL}/${courtId}/attorneys`,
    caseSearch: (courtId: number) => caseControllerURL(courtId),
    caseSearchByCaseClassId: (courtId: number, caseClassId: number) => `${caseControllerURL(courtId)}/caseclass/${caseClassId}`,
    getCase: (courtId: number, caseId: number) => `${caseControllerURL(courtId)}/${caseId}`,
    updateNewQueueCase: (courtId: number, entryId: number) => `${caseControllerURL(courtId)}/queues/${entryId}`,
    applyOrQueueCaseUpdate: (courtId: number, caseId: number) => `${caseControllerURL(courtId)}/${caseId}`,
    applyOrQueueNewCase: (courtId: number) => caseControllerURL(courtId),
    getParticipantsByCourt: (courtId: number) => `${courtController_CourtIdURL(courtId)}/participants`,
    getParticipantsByCase: (courtId: number, caseId: number) => `${caseControllerURL(courtId)}/${caseId}/participants`,
    getParticipantsByAttorney: (courtId: number, attorneyId: number) => `${courtController_CourtIdURL(courtId)}/attorneys/${attorneyId}/participants`,
    getCourtPersonnel: (courtId: number, term: string) => `${courtControllerURL}/${courtId}/personnel?term=${term}`,
    getCourtPersonnelByCase: (courtId: number, caseId: number) => `${caseControllerURL(courtId)}/${caseId}/personnel`,
    getCasesByPersonnel: (courtId: number, personId: number) => `${courtController_CourtIdURL(courtId)}/personnel/${personId}/cases`,
    getParticipantDetail: (courtId: number, caseId: number, partyId: number, partyTypeId: number) => `${caseControllerURL(courtId)}/${caseId}/participants/${partyId}/type/${partyTypeId}`,
    getCaseStatusCatalog: (courtId: number) => `${courtController_CourtIdURL(courtId)}/catalog/casestatuses`,
    getCaseClassCatalog: (courtId: number) => `${courtController_CourtIdURL(courtId)}/catalog/caseclasses`,
    getCaseTypeCatalog: (courtId: number, caseClassId: number) => `${courtController_CourtIdURL(courtId)}/catalog/casetypes/${caseClassId}`,
    getDivisionCatalog: (courtId: number) => `${courtController_CourtIdURL(courtId)}/catalog/divisions`,
    getJudgeCatalog: (courtId: number) => `${courtController_CourtIdURL(courtId)}/catalog/judges`,
    searchJudgeCatalog: (courtId: number) => `${courtController_CourtIdURL(courtId)}/catalog/judges/search`,
    getClerkCatalog: (courtId: number) => `${courtController_CourtIdURL(courtId)}/catalog/clerks`,
    searchClerkCatalog: (courtId: number) => `${courtController_CourtIdURL(courtId)}/catalog/clerks/search`,
    getAttorneyTypeCatalog: (courtId: number) => `${courtController_CourtIdURL(courtId)}/catalog/attorneytypes`,
    getCourtCaseClassInfo: (courtId: number, caseClassId: number) => `${courtController_CourtIdURL(courtId)}/caseclasses/${caseClassId}`,
    getCaseManagementCatalogs: (courtId: number, catalogs: string[] = []) => `${courtController_CourtIdURL(courtId)}/catalog/case-management?q=${catalogs.join()}`,
    attorneySearch: (courtId: number, term: string, searchInMyFirm: boolean) => `${courtController_CourtIdURL(courtId)}/attorneys?term=${term}${searchInMyFirm ? '&searchInMyFirm=true': ''}`,
    addJudgesClerksToCourtCatalog: (courtId: number) => `${courtController_CourtIdURL(courtId)}/catalog/judges-clerks`,
    getLowerCourts: (courtId: number) => `${courtController_CourtIdURL(courtId)}/lower-courts`,
    tempDocuments: `${documentsControllerURL}/temp`,
    appealableCaseSearch: (courtId: number) => `${caseControllerURL(courtId)}/appealable`,
    caseGroupSearch: (courtId: number) => `${courtController_CourtIdURL(courtId)}/case-groups`,
    getCaseGroup: (courtId: number, groupId: number) => `${courtController_CourtIdURL(courtId)}/case-groups/${groupId}`,
    caseSearchByCaseGroup: (courtId: number, groupId: number) => `${courtController_CourtIdURL(courtId)}/case-groups/${groupId}/cases`,
    addCaseGroup: (courtId: number) => `${courtController_CourtIdURL(courtId)}/case-groups`,
    deleteCaseGroup: (courtId: number, groupId: number) => `${courtController_CourtIdURL(courtId)}/case-groups/${groupId}`,
    removeCaseFromCaseGroup: (courtId: number, groupId: number) => `${courtController_CourtIdURL(courtId)}/case-groups/${groupId}/cases`,
    addCasesToCaseGroup: (courtId: number, groupId: number) => `${courtController_CourtIdURL(courtId)}/case-groups/${groupId}/cases`,

    getQueueCaseList: (courtId: number) => `${courtController_CourtIdURL(courtId)}/cases/queues`,
    getQueueAttorneyList: (courtId: number) => `${courtController_CourtIdURL(courtId)}/attorneys/queues`,
    getQueueParticipantList: (courtId: number) => `${courtController_CourtIdURL(courtId)}/participants/queues`,
    getQueueParticipantEntry: (courtId: number, entryId: number) => `${courtController_CourtIdURL(courtId)}/participants/queues/${entryId}`,
    getQueueCourtPersonnelList: (courtId: number) => `${courtController_CourtIdURL(courtId)}/personnel/queues`,

    approveQueueCaseList: (courtId: number) => `${courtController_CourtIdURL(courtId)}/cases/queues/approve`,
    approveQueueAttorneyList: (courtId: number) => `${courtController_CourtIdURL(courtId)}/attorneys/queues/approve`,
    approveQueueParticipantList: (courtId: number) => `${courtController_CourtIdURL(courtId)}/participants/queues/approve`,
    approveQueueCourtPersonnelList: (courtId: number) => `${courtController_CourtIdURL(courtId)}/personnel/queues/approve`,

    getQueueCaseEntry: (courtId: number, entryId: number) => `${courtController_CourtIdURL(courtId)}/cases/queues/${entryId}`,
    getQueueCourtPersonnelEntry: (courtId: number, entryId: number) => `${courtController_CourtIdURL(courtId)}/personnel/queues/${entryId}`,
    getQueueAttorneyEntry: (courtId: number, entryId: number) => `${courtController_CourtIdURL(courtId)}/attorneys/queues/${entryId}`,
    
    getCaseLoadList: (courtId: number,uploadType: string) => `${courtController_CourtIdURL(courtId)}/case-load`,
  }
}
