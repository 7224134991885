/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AccessCommonCategory {
  None = 'None',
  Other = 'Other',
  Public = 'Public',
  Private = 'Private',
}

export interface AccessCommonCategoryDomainCategoryValue {
  commonCategory: AccessCommonCategory;

  /**
   * Sequence indicates the order in which to display value to the user - sequence numbers do not have to be contiguous (sequences can have gaps)
   * @format int32
   */
  sequence: number;
  name: string;
  caption: string;
  efmKey: string;
}

/**
* FileAndServe.Efsp.Shared.Models.Actor will hold properties that drive behavior in the system.
            An actor is a component of a workflow in the system that isn't necessarily a user.
            For example, an actor could be necessary to provide native service to the contact.
            E.g. notification preferences.
*/
export type Actor = object;

export interface AdditionalFieldSpec {
  name: string;
  group?: string | null;
  fieldType: FieldCategory;

  /** Multiline, unformatted descriptive text of the field’s purpose. */
  helpText?: string | null;

  /** Brief text to describe the input (often used as background text in an input control). */
  hint?: string | null;
  addressFieldDefinition?: AddressFieldDefinition | null;
  aliasFieldDefinition?: AliasFieldDefinition | null;
  booleanFieldDefinition?: BooleanFieldDefinition | null;
  contactFieldDefinition?: ContactFieldDefinition | null;
  currencyFieldDefinition?: CurrencyFieldDefinition | null;
  dateFieldDefinition?: DateFieldDefinition | null;
  dateTimeFieldDefinition?: DateTimeFieldDefinition | null;
  documentFieldDefinition?: DocumentFieldDefinition | null;
  fileFieldDefinition?: FileFieldDefinition | null;
  instructionDefinition?: InstructionDefinition | null;
  numberFieldDefinition?: NumberFieldDefinition | null;
  participantFieldDefinition?: ParticipantFieldDefinition | null;
  searchFieldDefinition?: SearchFieldDefinition | null;
  selectionFieldDefinition?: StringSelectionFieldDefinition | null;
  textFieldDefinition?: TextFieldDefinition | null;
  timeFieldDefinition?: TimeFieldDefinition | null;
  caption: string;
}

export interface AdditionalFieldValue {
  additionalFieldName: string;
  addressValues?: Address[] | null;
  aliasValues?: RequestParticipantAlias[] | null;
  booleanValue?: boolean | null;
  contactValues?: RequestContact[] | null;
  currencyValue?: CurrencyValue | null;

  /** @format date-time */
  dateValue?: string | null;

  /** @format date-time */
  dateTimeValue?: string | null;
  documentValue?: DomainFieldValue[] | null;
  fileValues?: string[] | null;
  numberValue?: string | null;
  participantValues?: DomainFieldValue[] | null;
  searchResultItem?: SearchResultItem | null;
  selectionValue?: string[] | null;
  textValue?: string | null;

  /**
   * Time of day - format 24 hour time units divided by colon, ex. 17:23:18, 08:16:59
   * @format date-span
   */
  timeValue?: string | null;
}

export interface Address {
  /** The address category (e.g. "Home", "Business"). */
  category?: AddressCategory | null;

  /** A user-assigned label (e.g. "My favorite", "Main"). */
  caption: string;

  /** Address line 1. */
  addressLine1: string;

  /** Address line 2. */
  addressLine2?: string | null;

  /** A named, densely populated area (a place) such as city, town, village, suburb, etc. */
  locality: string;

  /** The top-level area division in the country, such as state, district, province, island, region. */
  administrativeArea?: string | null;

  /** The Zip code or equivalent. */
  postalCode: string;

  /** The Zip code extra digits (+4 or +6). */
  postalCodeExtension?: string | null;

  /** The country. */
  country: string;
}

export interface AddressCategory {
  name: string;
  caption: string;
  commonCategory: AddressCommonCategory;
}

export interface AddressCategorySelectionFieldDefinition {
  /**
   * Minimum number of selections required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of selections allowed.
   * @format int32
   */
  maxAllowed: number;

  /** The reference to the list of options to present. */
  listReference: ProfileListReference;

  /** The default value to present to the user. */
  defaultValue: AddressCategory;

  /** Additional fields that appear depending on the selected values. */
  selectionDependentFields: SelectionDependentFields[];
  readOnly: boolean;
  visible: boolean;
}

export enum AddressCommonCategory {
  None = 'None',
  Other = 'Other',
  Home = 'Home',
  Business = 'Business',
  PoBox = 'PoBox',
}

export interface AddressCommonCategoryDomainCategoryValue {
  commonCategory: AddressCommonCategory;

  /**
   * Sequence indicates the order in which to display value to the user - sequence numbers do not have to be contiguous (sequences can have gaps)
   * @format int32
   */
  sequence: number;
  name: string;
  caption: string;
  efmKey: string;
}

export interface AddressFieldDefinition {
  readOnly: boolean;
  visible: boolean;

  /**
   * Minimum number of addresses required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of addresses allowed.
   * @format int32
   */
  maxAllowed: number;

  /** The name of the applicable FileAndServe.Efsp.Shared.Models.Profiles.AddressProfile. */
  addressProfileName: string;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.Country" />. */
  country: StringSelectionFieldDefinition;

  /** Field definition for the category on an entity. */
  category?: AddressCategorySelectionFieldDefinition | null;
  description?: string | null;
  additionalFields?: AdditionalFieldSpec[] | null;
}

export interface AddressProfile {
  name: string;
  spec: AddressProfileSpec;
  countrySpecs: AddressProfileCountry[];
}

export interface AddressProfileCountry {
  countryName: string;
  spec: AddressProfileSpec;
}

export interface AddressProfileSpec {
  /** Custom caption for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.AddressLine1" /> */
  addressLine1Caption?: string | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.AddressLine1" /> */
  addressLine1: TextFieldDefinition;

  /** Custom caption for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.AddressLine2" /> */
  addressLine2Caption?: string | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.AddressLine2" /> */
  addressLine2?: TextFieldDefinition | null;

  /** Custom caption for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.Locality" /> */
  localityCaption?: string | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.Locality" /> */
  locality: TextFieldDefinition;

  /** Custom caption for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.AdministrativeArea" /> */
  administrativeAreaCaption?: string | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.AdministrativeArea" /> */
  administrativeArea?: StringSelectionFieldDefinition | null;

  /** Custom caption for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.PostalCode" /> */
  postalCodeCaption?: string | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.PostalCode" /> */
  postalCode: TextFieldDefinition;
}

export interface AddressSpec {
  /**
   * Minimum number of addresses required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of addresses allowed.
   * @format int32
   */
  maxAllowed: number;

  /** The name of the applicable FileAndServe.Efsp.Shared.Models.Profiles.AddressProfile. */
  addressProfileName: string;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.AddressBase.Country" />. */
  country: StringSelectionFieldDefinition;

  /** Field definition for the category on an entity. */
  category?: AddressCategorySelectionFieldDefinition | null;
  description?: string | null;
  additionalFields?: AdditionalFieldSpec[] | null;
}

export interface AliasCategory {
  name: string;
  caption: string;
  commonCategory: AliasCommonCategory;
}

export enum AliasCommonCategory {
  None = 'None',
  Other = 'Other',
  Aka = 'Aka',
  Fka = 'Fka',
  Fdba = 'Fdba',
}

export interface AliasCommonCategoryDomainCategoryValue {
  commonCategory: AliasCommonCategory;

  /**
   * Sequence indicates the order in which to display value to the user - sequence numbers do not have to be contiguous (sequences can have gaps)
   * @format int32
   */
  sequence: number;
  name: string;
  caption: string;
  efmKey: string;
}

export interface AliasFieldDefinition {
  /**
   * Allowed categories for each alias.
   * FileAndServe.Efsp.Shared.Models.Profiles.ProfileLists.AliasCategories allows all available alias categories.
   */
  allowedAliasCategoriesList: ProfileListReference;

  /**
   * Minimum number of aliases required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of aliases allowed.
   * @format int32
   */
  maxAllowed: number;
  readOnly: boolean;
  visible: boolean;
}

export interface AliasSpec {
  aliasCategory: AliasCategory;
  allowPerson: boolean;
  allowOrganization: boolean;

  /** Spec for: <inheritdoc cref="T:FileAndServe.Efsp.Shared.Models.Base.PersonBase" /> */
  person?: PersonSpec | null;

  /** Spec for: <inheritdoc cref="T:FileAndServe.Efsp.Shared.Models.ContactOrganization" /> */
  organization?: OrganizationSpec | null;
  identification?: IdentificationSpec | null;
  address?: AddressSpec | null;
  phone?: PhoneSpec | null;
  email?: EmailAddressSpec | null;
  description?: string | null;
  additionalFields?: AdditionalFieldSpec[] | null;
}

export interface AttestRequest {
  statement: string;
  answer: string;
}

export interface BooleanFieldDefinition {
  additionalFields?: AdditionalFieldSpec[] | null;
  readOnly: boolean;
  visible: boolean;
}

export interface CaseDetail {
  /** Transport object for summary level case information from the EFMs */
  summary: CaseSummary;

  /** The parties to the cases. */
  parties: CaseParty[];

  /** The contact information for all case participants. This can be referenced by FileAndServe.Efsp.Shared.Models.CaseParty, FileAndServe.Efsp.Shared.Models.Request.RequestParticipantRepresentation and other elements in the filing. */
  participants: RequestParticipant[];

  /** The documents in the case. */
  documents: CaseDocument[];

  /** Additional information about the case. */
  additionalLists: NamedList[];
}

export interface CaseDocument {
  /** The document's last known status with the EFM. */
  status: DocumentStatus;

  /** An identifier for the document, unique within the case. */
  name: string;

  /** The EFM identifier for the document. */
  efmKey: string;

  /** The document Title. */
  title: string;

  /** The document's content type (MIME type). */
  contentType: string;

  /** The document's category as per the case's filing profile. */
  category?: DocumentCategory | null;

  /** Notes on record about the document. */
  notes: string;

  /**
   * Document size in bytes.
   * @format int64
   */
  size?: number | null;

  /**
   * Number of pages in the document.
   * @format int64
   */
  pages?: number | null;

  /**
   * Date on which the document takes effect in the case.
   * @format date-time
   */
  effectiveDate: string;

  /**
   * Date on which the document was officially filed.
   * @format date-time
   */
  filedDate?: string | null;
  events: Event[];

  /** Participants who filed the document. */
  filedBy: RequestDocumentParticipant[];

  /** Participants addressed in the document. */
  filedAsTo: RequestDocumentParticipant[];

  /** Relation to other documents in the case. */
  relatedDocuments: CaseDocument[];

  /** Values for additional fields as described by FileAndServe.Efsp.Shared.Models.Profiles.Fields.AdditionalFieldSpec. */
  additionalFieldValues: AdditionalFieldValue[];
}

export interface CaseFilingEntity {
  /** A condensed view of a contact. */
  authorizer: ContactSummary;

  /** A condensed view of a contact. */
  filer: ContactSummary;

  /** A condensed view of a contact. */
  reviewer: ContactSummary;
  filingStatus: FilingState;

  /** @format date-time */
  draftDate?: string | null;

  /** @format date-time */
  scheduledTimestamp?: string | null;

  /** @format date-time */
  authorizedTimestamp?: string | null;

  /** @format date-time */
  filingTimestamp?: string | null;

  /** @format date-time */
  reviewedTimestamp?: string | null;
  filerNote: string;
  filingOption: FilingOption;
  isOriginalPetitionFiling: boolean;
  multiCase: boolean;

  /** @format uuid */
  organizationId: string;

  /** @format uuid */
  caseId: string;

  /** @format uuid */
  id: string;
  createdBy: string;

  /** @format date-time */
  createdAt: string;
  modifiedBy: string;

  /** @format date-time */
  modifiedAt: string;
  bookmarked: boolean;
}

export interface CaseFilingEntityGridResult {
  /**
   * The (possibly approximate) total number of results.
   * @format int32
   */
  total: number;

  /**
   * The number of results being returned
   * @format int32
   */
  count: number;

  /**
   * The maximum number of results requested
   * @format int32
   */
  limit: number;

  /**
   * The number of results skipped.
   * @format int32
   */
  skip: number;

  /** True of the total is an exact number of total results, false if it is an approximation. */
  exactTotal: boolean;

  /** Optional. The filter expression used to narrow the results */
  filters?: Filter[] | null;

  /** Details of the sort column and order */
  sort?: Sort[] | null;

  /** Gets or sets an System.Collections.Generic.IList`1 containing the current page of data. */
  data: CaseFilingEntity[];
}

export interface CaseParty {
  /**
   * The Id for the case. Can be left null for opf.
   * @format uuid
   */
  caseId?: string | null;

  /** Readable text identifying the party. E.g. "Green, Lisa". */
  caption: string;

  /** The name reference to the participant. */
  participantName: string;

  /** The EfmKey for the party. */
  efmKey?: string | null;

  /** The category of the participant. */
  participantCategory?: ParticipantCategory | null;

  /** Additional qualities of the party, such as "Minor". */
  participantSubCategoryNames?: string[] | null;

  /** Details about the participant's representation in court, such as the attorneys. */
  representation?: RequestParticipantRepresentation[] | null;

  /** 'True' indicates the participant is self-represented. */
  isSelfRepresented: boolean;

  /** Values for additional fields as described by FileAndServe.Efsp.Shared.Models.Profiles.Fields.AdditionalFieldSpec. */
  additionalFieldValues?: AdditionalFieldValue[] | null;
}

/**
 * Wrapper to integrate Case Summary properties to handle Profile settings' state
 */
export interface CaseQueryResultItem {
  /** Case summary data */
  summary: CaseSummary;

  /** Indicates that the attestation requirements for accessing the Case are not met */
  pendingAttestation: boolean;
}

export interface CaseQueryResultItemGridResult {
  /**
   * The (possibly approximate) total number of results.
   * @format int32
   */
  total: number;

  /**
   * The number of results being returned
   * @format int32
   */
  count: number;

  /**
   * The maximum number of results requested
   * @format int32
   */
  limit: number;

  /**
   * The number of results skipped.
   * @format int32
   */
  skip: number;

  /** True of the total is an exact number of total results, false if it is an approximation. */
  exactTotal: boolean;

  /** Optional. The filter expression used to narrow the results */
  filters?: Filter[] | null;

  /** Details of the sort column and order */
  sort?: Sort[] | null;

  /** Gets or sets an System.Collections.Generic.IList`1 containing the current page of data. */
  data: CaseQueryResultItem[];
}

export interface CaseRequest {
  /** The cases, whether new or existing, that the filing is for. */
  cases?: RequestCase[] | null;

  /** The parties to the cases. */
  parties?: CaseParty[] | null;

  /** The contact information for all case participants. This can be referenced by FileAndServe.Efsp.Shared.Models.CaseParty, FileAndServe.Efsp.Shared.Models.Request.RequestParticipantRepresentation and other elements in the filing. */
  participants?: RequestParticipant[] | null;

  /** The documents to file. */
  documents?: RequestDocument[] | null;

  /** A note to whoever reviews the submitted filing on behalf of the court. */
  noteToClerk?: string | null;
}

/**
 * A single request to create or file into an existing case.
 */
export interface CaseRequestCaseSummary {
  /** The short form title of the case. */
  title: string;

  /** The docket style case numbering. */
  caption: string;

  /** The tracking/primary identifier provided by the EFM. */
  efmKey: string;

  /** Status of the current filing attempt into the case */
  status: CaseRequestSubStatus;
}

export enum CaseRequestSubStatus {
  Unknown = 'Unknown',
  Draft = 'Draft',
  Accepted = 'Accepted',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

export interface CaseSpec {
  amountInControversy?: CurrencyFieldDefinition | null;
  incidentAddress?: AddressSpec | null;
  description?: string | null;
  additionalFields?: AdditionalFieldSpec[] | null;
}

/**
 * Transport object for summary level case information from the EFMs
 */
export interface CaseSummary {
  /**
   * The Id for the case.
   * @format uuid
   */
  id: string;

  /** The court the case is in. */
  court: CourtSummary;

  /** The short form title of the case. */
  title: string;

  /** The docket style case numbering. */
  caption: string;

  /** The tracking/primary identifier provided by the EFM. */
  efmKey: string;

  /** The status provided by the EFM. */
  status: string;

  /** The filing profile applicable to the case. */
  filingProfileSummary: FilingProfileSummary;
}

/**
* Summary object describing the top level data of a case. This data is tracked in MRUs and
bookmarks, and search optimized by key terms.
*/
export interface CaseSummaryEntity {
  caseTypeCode: CaseType;
  title: string;
  categoryCode: string;
  categoryDescription: string;
  efmReferenceKey: string;
  caption: string;
  caseTypeDescription: string;
  courtIdentificationKey: string;

  /** @format date-time */
  lastUsedAt?: string | null;

  /** @format uuid */
  organizationId: string;

  /** @format uuid */
  id: string;
  createdBy: string;

  /** @format date-time */
  createdAt: string;
  modifiedBy: string;

  /** @format date-time */
  modifiedAt: string;
  bookmarked: boolean;
}

export interface CaseSummaryEntityGridResult {
  /**
   * The (possibly approximate) total number of results.
   * @format int32
   */
  total: number;

  /**
   * The number of results being returned
   * @format int32
   */
  count: number;

  /**
   * The maximum number of results requested
   * @format int32
   */
  limit: number;

  /**
   * The number of results skipped.
   * @format int32
   */
  skip: number;

  /** True of the total is an exact number of total results, false if it is an approximation. */
  exactTotal: boolean;

  /** Optional. The filter expression used to narrow the results */
  filters?: Filter[] | null;

  /** Details of the sort column and order */
  sort?: Sort[] | null;

  /** Gets or sets an System.Collections.Generic.IList`1 containing the current page of data. */
  data: CaseSummaryEntity[];
}

export enum CaseType {
  Unknown = 'Unknown',
  Appellate = 'Appellate',
  Bankruptcy = 'Bankruptcy',
  Citation = 'Citation',
  Civil = 'Civil',
  Criminal = 'Criminal',
  Domestic = 'Domestic',
  Juvenile = 'Juvenile',
}

export interface CharacterProfile {
  name: string;
  characterSet: string;
}

export interface Contact {
  /** <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.IContact.ContactType" /> */
  type: ContactType;
  caption: string;

  /** User details. Applies to FileAndServe.Efsp.Shared.Models.ContactType.User contacts. */
  user?: UserData | null;

  /**
   * Actor details. Applies to FileAndServe.Efsp.Shared.Models.ContactType.Actor contacts.
   * <inheritdoc cref="T:FileAndServe.Efsp.Shared.Models.Actor" />
   */
  actor?: Actor | null;

  /** A contact person. */
  person?: ContactPerson | null;

  /** A contact organization. It may have a person contact as a primary contact. */
  organization?: ContactOrganization | null;
  addresses: Address[];
  emails: EmailAddress[];
  identifications: Identification[];
  phones: Phone[];

  /** Aliases the contact goes by. */
  aliases: ContactAlias[];

  /** Lightweight reference to a contact. */
  parentOrganization?: ContactReference | null;

  /** The own or inherited ClientNameText. */
  effectiveClientNameText?: string | null;

  /** @format uuid */
  organizationId: string;

  /** @format uuid */
  id: string;
  createdBy: string;

  /** @format date-time */
  createdAt: string;
  modifiedBy: string;

  /** @format date-time */
  modifiedAt: string;
  bookmarked: boolean;
}

/**
 * Alternative identities a contact may go by, each with its own contact methods.
 */
export interface ContactAlias {
  /** The alias category (e.g. "Also Known As", etc.). */
  category: AliasCategory;
  contactType: ContactType;
  caption: string;

  /** A contact person. */
  person?: ContactPerson | null;

  /** A contact organization. It may have a person contact as a primary contact. */
  organization?: ContactOrganization | null;
  addresses: Address[];
  emails: EmailAddress[];
  identifications: Identification[];
  phones: Phone[];
}

export interface ContactFieldDefinition {
  /**
   * Minimum number of entities required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of entities allowed.
   * @format int32
   */
  maxAllowed: number;
  allowPerson: boolean;
  allowOrganization: boolean;

  /** Spec for: <inheritdoc cref="T:FileAndServe.Efsp.Shared.Models.Base.PersonBase" /> */
  person?: PersonSpec | null;

  /** Spec for: <inheritdoc cref="T:FileAndServe.Efsp.Shared.Models.ContactOrganization" /> */
  organization?: OrganizationSpec | null;
  identification?: IdentificationSpec | null;
  address?: AddressSpec | null;
  phone?: PhoneSpec | null;
  email?: EmailAddressSpec | null;
  additionalFields?: AdditionalFieldSpec[] | null;
  readOnly: boolean;
  visible: boolean;
}

/**
 * A contact organization. It may have a person contact as a primary contact.
 */
export interface ContactOrganization {
  /** The organization's primary contact person. */
  primaryContact?: ContactReference | null;

  /** An external value used to relate the contact to external records (e.g. a client ID for a firm's billing purposes). */
  clientNameText?: string | null;

  /** The legal name of the organization */
  title: string;

  /** A short name for the organization */
  caption: string;
}

/**
 * A contact person.
 */
export interface ContactPerson {
  /** The personal name. */
  personalName: PersonalName;

  /**
   * The date of death.
   * @format date-time
   */
  dateOfDeath?: string | null;

  /**
   * The date of birth.
   * @format date-time
   */
  dateOfBirth?: string | null;
}

/**
 * The organization's general specs. Where there's a purpose-specific profile (e.g. filings have the FileAndServe.Efsp.Shared.Models.Profiles.FilingProfile) that profile fully overrides this one.
 */
export interface ContactProfile {
  /** The specs for FileAndServe.Efsp.Shared.Models.Cosmos.Contact. */
  contact: ContactSpec;

  /** The alias categories. */
  aliasCategories: AliasCategory[];

  /** The address categories. */
  addressCategories: AddressCategory[];

  /** The email categories. */
  emailCategories: EmailCategory[];

  /** The identification categories. */
  identificationCategories: IdentificationCategory[];

  /** The phone categories. */
  phoneCategories: PhoneCategory[];
  alias: AliasSpec[];
  characterProfiles: CharacterProfile[];
  defaultCharacterProfileName: string;
  addressProfiles: AddressProfile[];
  defaultAddressProfileName: string;
  additionalLists: NamedList[];
}

export interface ContactQueryResultItem {
  /** The contact summary. */
  contactSummary: ContactSummary;

  /** The contact's identifications. */
  identifications?: Identification[] | null;
}

export interface ContactQueryResultItemGridResult {
  /**
   * The (possibly approximate) total number of results.
   * @format int32
   */
  total: number;

  /**
   * The number of results being returned
   * @format int32
   */
  count: number;

  /**
   * The maximum number of results requested
   * @format int32
   */
  limit: number;

  /**
   * The number of results skipped.
   * @format int32
   */
  skip: number;

  /** True of the total is an exact number of total results, false if it is an approximation. */
  exactTotal: boolean;

  /** Optional. The filter expression used to narrow the results */
  filters?: Filter[] | null;

  /** Details of the sort column and order */
  sort?: Sort[] | null;

  /** Gets or sets an System.Collections.Generic.IList`1 containing the current page of data. */
  data: ContactQueryResultItem[];
}

/**
 * Lightweight reference to a contact.
 */
export interface ContactReference {
  /**
   * The contact Id.
   * @format uuid
   */
  id: string;

  /** A user-assigned label (e.g. "My favorite", "Main"). */
  caption: string;

  /** The contact type. */
  contactType: ContactType;

  /** An external value used to relate the contact to external records (e.g. a client ID for a firm's billing purposes). */
  clientNameText?: string | null;
}

export interface ContactSpec {
  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Cosmos.Contact.Caption" /> */
  caption: TextFieldDefinition;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Person" /> */
  person?: PersonSpec | null;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Organization" /> */
  organization?: OrganizationSpec | null;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Addresses" /> */
  address: AddressSpec;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Emails" /> */
  email: EmailAddressSpec;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Identifications" /> */
  identification: IdentificationSpec;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Phones" /> */
  phone: PhoneSpec;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Cosmos.Contact.Aliases" /> */
  alias: AliasFieldDefinition;
}

/**
 * A condensed view of a contact.
 */
export interface ContactSummary {
  /**
   * The contact Id.
   * @format uuid
   */
  id: string;

  /** The type (person, organization, etc) of contact. */
  type: ContactType;

  /** A user-assigned label (e.g. "My favorite", "Main"). */
  caption: string;

  /** The primary email address for the contact. */
  primaryEmailAddress?: string | null;

  /** Lightweight reference to a contact. */
  parentOrganization?: ContactReference | null;

  /** An external value used to relate the contact to external records (e.g. a client ID for a firm's billing purposes). */
  clientNameText?: string | null;
}

export enum ContactType {
  Unknown = 'Unknown',
  Organization = 'Organization',
  Person = 'Person',
  Actor = 'Actor',
  User = 'User',
  TenantOrganization = 'TenantOrganization',
}

export enum UserGroup {
  Firm = '1',
  Court = '2',
  JusticeLink = '3',
  PublicAccess = '4'
}

export interface CourtCaseGroupInfo {
  /** @format int32 */
  id: number;

  /** @format int32 */
  courtId: number;
  cases: CourtCaseGroupMemberInfo[];
  name: string;

  /** @format int32 */
  organizationId: number;
  isPublic: boolean;

  /** @format int32 */
  userId: number;

  /** @format date-time */
  dateCreated: string;
}

export interface CourtCaseGroupInfoGridResult {
  /**
   * The (possibly approximate) total number of results.
   * @format int32
   */
  total: number;

  /**
   * The number of results being returned
   * @format int32
   */
  count: number;

  /**
   * The maximum number of results requested
   * @format int32
   */
  limit: number;

  /**
   * The number of results skipped.
   * @format int32
   */
  skip: number;

  /** True of the total is an exact number of total results, false if it is an approximation. */
  exactTotal: boolean;

  /** Optional. The filter expression used to narrow the results */
  filters?: Filter[] | null;

  /** Details of the sort column and order */
  sort?: Sort[] | null;

  /** Gets or sets an System.Collections.Generic.IList`1 containing the current page of data. */
  data: CourtCaseGroupInfo[];
}

export interface CourtCaseGroupMemberInfo {
  /** @format int32 */
  caseId: number;
  caseName: string;
  caseNumber: string;
  active: boolean;
}

export interface CourtCaseGroupMemberInfoGridResult {
  /**
   * The (possibly approximate) total number of results.
   * @format int32
   */
  total: number;

  /**
   * The number of results being returned
   * @format int32
   */
  count: number;

  /**
   * The maximum number of results requested
   * @format int32
   */
  limit: number;

  /**
   * The number of results skipped.
   * @format int32
   */
  skip: number;

  /** True of the total is an exact number of total results, false if it is an approximation. */
  exactTotal: boolean;

  /** Optional. The filter expression used to narrow the results */
  filters?: Filter[] | null;

  /** Details of the sort column and order */
  sort?: Sort[] | null;

  /** Gets or sets an System.Collections.Generic.IList`1 containing the current page of data. */
  data: CourtCaseGroupMemberInfo[];
}

export interface CourtSummary {
  /** @format uuid */
  id: string;
  caption: string;
  region: string;
  timeZone: string;
  efmKey: string;
}

export interface CourtSummaryEnvelope {
  /** Gets or sets an System.Collections.Generic.IList`1 containing the current page of data. */
  data: CourtSummary[];
}

export interface CourtSummaryItemTree {
  items?: CourtSummary[] | null;
  description?: string | null;
  childLabel?: string | null;
  childDescription?: string | null;
  children?: CourtSummaryItemTree[] | null;
  name: string;
  caption: string;
}

export interface CourtSummaryItemTreeTreeResult {
  /** @format int32 */
  itemCount: number;
  itemLabel?: string | null;
  data?: CourtSummaryItemTree | null;
}

export interface CurrencyFieldDefinition {
  /** Indicates that a value is required. */
  required: boolean;

  /**
   * Minimum accepted value, in dollars
   * @format double
   */
  minValue?: number | null;

  /**
   * Maximum accepted value, in dollars
   * @format double
   */
  maxValue?: number | null;

  /**
   * Default/initial value, in dollars
   * @format double
   */
  defaultValue?: number | null;

  /** Indicates whether decimals (cents) are allowed. */
  allowDecimals: boolean;
  readOnly: boolean;
  visible: boolean;
}

export interface CurrencyValue {
  /** CategoryName - currently unused; future use for identifying which currency is meant (dollars, euros, pesos, etc) */
  currencyKey?: string | null;

  /**
   * The currency amount.
   * @format double
   */
  amount: number;
}

export interface DataAttribute {
  /** The label text */
  label: string;

  /** The readable value text */
  caption: string;

  /** The key for the value when applicable */
  key?: string | null;
}

export interface DateFieldDefinition {
  /** Indicates that a value is required. */
  required: boolean;

  /** @format int32 */
  daysToDateRangeStart: number;

  /** @format int32 */
  daysToDateRangeEnd: number;

  /** The timezone applicable to the field. */
  timeZone: string;
  readOnly: boolean;
  visible: boolean;
}

export interface DateTimeFieldDefinition {
  /** Indicates that a value is required. */
  required: boolean;

  /** @format int32 */
  daysToDateRangeStart: number;

  /**
   * <inheritdoc />
   *             The resulting End Date is valid for the entire day in the TimeZone specified by FileAndServe.Efsp.Shared.Models.Profiles.Fields.DateTimeFieldDefinition.TimeZone.
   *             (e.g. if FileAndServe.Efsp.Shared.Models.Profiles.Fields.DateTimeFieldDefinition.DaysToDateRangeEnd is 1, meaning tomorrow, and FileAndServe.Efsp.Shared.Models.Profiles.Fields.DateTimeFieldDefinition.MinTime is 07:00:00, tomorrow at 7:00 am is a valid value)
   * @format int32
   */
  daysToDateRangeEnd: number;

  /** @format date-span */
  minTime: string;

  /** @format date-span */
  maxTime: string;

  /** The timezone applicable to the field. */
  timeZone: string;
  readOnly: boolean;
  visible: boolean;
}

export enum Direction {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export interface DocumentCategory {
  name: string;
  caption: string;
  commonCategory: DocumentCommonCategory;
}

export enum DocumentCheck {
  None = 'None',
  FileType = 'FileType',
  Malware = 'Malware',
}

export enum DocumentCommonCategory {
  None = 'None',
  Other = 'Other',
  LeadDocument = 'LeadDocument',
  SupportingDocument = 'SupportingDocument',
}

export interface DocumentCommonCategoryDomainCategoryValue {
  commonCategory: DocumentCommonCategory;

  /**
   * Sequence indicates the order in which to display value to the user - sequence numbers do not have to be contiguous (sequences can have gaps)
   * @format int32
   */
  sequence: number;
  name: string;
  caption: string;
  efmKey: string;
}

export interface DocumentFieldDefinition {
  /**
   * Document types allowed.
   * Must use FileAndServe.Efsp.Shared.Models.Profiles.ProfileLists.AdditionalList.
   */
  allowedDocumentsList: ProfileListReference;

  /**
   * Minimum number of entities allowed.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of documents allowed.
   * @format int32
   */
  maxAllowed: number;
  readOnly: boolean;
  visible: boolean;
}

export interface DocumentInfo {
  /** @format uuid */
  id: string;
  displayName: string;
  originalFileName: string;

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt: string;
  pendingChecks: DocumentCheck[];
  passedChecks: DocumentCheck[];
  failedChecks: DocumentCheck[];
  selfUrl: string;
  readOnly: boolean;
  renderings?: DocumentRenderingInfo[] | null;
}

export interface DocumentRenderingInfo {
  name: string;
  contentType: string;

  /** @format int64 */
  size: number;

  /** @format int64 */
  pageCount: number;
  status: RenderingStatus;
  statusMessage?: string | null;

  /** @format date-time */
  createdAt: string;

  /** @format date-time */
  updatedAt: string;
  url?: string | null;
}

export interface DocumentSpec {
  documentCategory: DocumentCommonCategoryDomainCategoryValue;
  applicableWithExistingCaseReference: boolean;
  applicableWithoutExistingCaseReference: boolean;
  title: TextFieldDefinition;
  fileName: TextFieldDefinition;
  filedBy?: ParticipantFieldSpec | null;
  asTo?: ParticipantFieldSpec | null;
  file?: FileFieldDefinition | null;
  description?: string | null;
  additionalFields?: AdditionalFieldSpec[] | null;
}

export enum DocumentStatus {
  Unknown = 'Unknown',
  Received = 'Received',
  Filed = 'Filed',
  Issued = 'Issued',
  Rejected = 'Rejected',
  ProposedReceived = 'ProposedReceived',
  FiledGranted = 'FiledGranted',
}

export interface DomainFieldValue {
  additionalFieldValues?: AdditionalFieldValue[] | null;

  /**
   * Sequence indicates the order in which to display value to the user - sequence numbers do not have to be contiguous (sequences can have gaps)
   * @format int32
   */
  sequence: number;
  name: string;
  caption: string;
  efmKey: string;
}

export enum EfmStatus {
  Unknown = 'Unknown',
  Queued = 'Queued',
  Error = 'Error',
  Pending = 'Pending',
  Processed = 'Processed',
}

export interface EmailAddress {
  /** The email category (e.g. "Personal", "Work"). */
  category?: EmailCategory | null;

  /** A user-assigned label (e.g. "My favorite", "Main"). */
  caption: string;

  /** The full email address. */
  address: string;
}

export interface EmailAddressSpec {
  /**
   * Minimum number of emails required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of emails allowed.
   * @format int32
   */
  maxAllowed: number;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.EmailBase.Address" />. */
  address: TextFieldDefinition;

  /** Field definition for the category on an entity. */
  category?: EmailCategorySelectionFieldDefinition | null;
  description?: string | null;
  additionalFields?: AdditionalFieldSpec[] | null;
}

export interface EmailCategory {
  name: string;
  caption: string;
  commonCategory: EmailCommonCategory;
}

export interface EmailCategorySelectionFieldDefinition {
  /**
   * Minimum number of selections required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of selections allowed.
   * @format int32
   */
  maxAllowed: number;

  /** The reference to the list of options to present. */
  listReference: ProfileListReference;

  /** The default value to present to the user. */
  defaultValue: EmailCategory;

  /** Additional fields that appear depending on the selected values. */
  selectionDependentFields: SelectionDependentFields[];
  readOnly: boolean;
  visible: boolean;
}

export enum EmailCommonCategory {
  None = 'None',
  Other = 'Other',
  Work = 'Work',
  School = 'School',
  Organization = 'Organization',
  Personal = 'Personal',
}

export interface EmailCommonCategoryDomainCategoryValue {
  commonCategory: EmailCommonCategory;

  /**
   * Sequence indicates the order in which to display value to the user - sequence numbers do not have to be contiguous (sequences can have gaps)
   * @format int32
   */
  sequence: number;
  name: string;
  caption: string;
  efmKey: string;
}

export interface Event {
  /** @format date-time */
  timestamp: string;
  type: EventType;
  efmKey: string;
}

export interface EventType {
  name: string;
  caption: string;
}

export enum FieldCategory {
  None = 'None',
  Boolean = 'Boolean',
  Contact = 'Contact',
  Currency = 'Currency',
  DateTime = 'DateTime',
  Document = 'Document',
  File = 'File',
  Instruction = 'Instruction',
  Number = 'Number',
  Participant = 'Participant',
  Search = 'Search',
  Selection = 'Selection',
  Text = 'Text',
  Date = 'Date',
  Time = 'Time',
  Address = 'Address',
  Alias = 'Alias',
}

export interface FileFieldDefinition {
  /** The name of the applicable FileAndServe.Efsp.Shared.Models.Profiles.FileTypeProfile. */
  fileTypeProfileName: string;

  /**
   * Minimum number of files required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of files allowed.
   * @format int32
   */
  maxAllowed: number;

  /**
   * File size limit for each individual file in the field.
   * @format int32
   */
  fileSizeMaxBytes: number;

  /**
   * Max length for the file's filename.
   * @format int32
   */
  fileNameMaxLength: number;

  /** Indicates that the size of the files in this field doesn't count toward the total file size. */
  ignoredByTotalFileSize: boolean;

  /** Indicates that the original file is to be sent, as opposed to the Converted PDF. */
  sendOriginal: boolean;
  readOnly: boolean;
  visible: boolean;
}

export interface FileType {
  contentType: string;
  caption: string;
  filenameExtensions: string[];
}

export interface FileTypeProfile {
  name: string;
  allowedContentTypes: string[];
}

export interface Filing {
  /**
   * The id of the court the filing is for.
   * @format uuid
   */
  courtId: string;

  /** The court the filing is for. */
  courtSummary: CourtSummary;

  /**
   * The filing profile id.
   * @format uuid
   */
  profileId: string;

  /**
   * The filer contact id.
   * @format uuid
   */
  filerUserId: string;

  /** The filer contact. */
  filer: ContactSummary;

  /**
   * The authorizer contact id.
   * @format uuid
   */
  authorizerUserId?: string | null;

  /** The authorizer contact. */
  authorizer: ContactSummary;

  /**
   * The moment the filing was created.
   * @format date-time
   */
  createdAt: string;

  /**
   * The moment the filing was last updated.
   * @format date-time
   */
  updatedAt?: string | null;

  /**
   * The moment the filing was submitted to the court.
   * @format date-time
   */
  submittedAt?: string | null;

  /**
   * The moment the filing is scheduled to be submitted.
   * @format date-time
   */
  scheduledAt?: string | null;

  /** The FileAndServe.Efsp.Shared.Constants.FilingMode. */
  mode: FilingMode;

  /** The FileAndServe.Efsp.Shared.Models.Cosmos.FilingState. */
  status: FilingState;
  filingOption: FilingOption;
  caption: string;

  /**
   * Number of cases participating in the filing.
   * @format int32
   */
  numberOfCases?: number | null;

  /**
   * The moment the filing was last accessed.
   * @format date-time
   */
  lastUsedAt?: string | null;
  courtCases: CaseRequestCaseSummary[];

  /** @format uuid */
  organizationId: string;

  /** @format uuid */
  id: string;
  createdBy: string;
  modifiedBy: string;

  /** @format date-time */
  modifiedAt: string;
  bookmarked: boolean;
}

export interface FilingEfmStatusMetric {
  status: EfmStatus;

  /** @format int32 */
  count: number;
}

export interface FilingGridResult {
  /**
   * The (possibly approximate) total number of results.
   * @format int32
   */
  total: number;

  /**
   * The number of results being returned
   * @format int32
   */
  count: number;

  /**
   * The maximum number of results requested
   * @format int32
   */
  limit: number;

  /**
   * The number of results skipped.
   * @format int32
   */
  skip: number;

  /** True of the total is an exact number of total results, false if it is an approximation. */
  exactTotal: boolean;

  /** Optional. The filter expression used to narrow the results */
  filters?: Filter[] | null;

  /** Details of the sort column and order */
  sort?: Sort[] | null;

  /** Gets or sets an System.Collections.Generic.IList`1 containing the current page of data. */
  data: Filing[];
}

export interface FilingMetrics {
  draft: FilingStatusMetric;
  scheduled: FilingStatusMetric;
  rejected: FilingStatusMetric;
  accepted: FilingStatusMetric;
  cancelled: FilingStatusMetric;
}

export enum FilingMode {
  None = 'None',
  OriginalPetition = 'OriginalPetition',
  Subsequent = 'Subsequent',
  Appellate = 'Appellate',
}

export interface FilingModeSpec {
  /** Whether filing is allowed. */
  allowFiling: boolean;

  /** Whether filing (or service) by self-represented users is allowed. */
  allowFilingBySelfRepresentedUsers: boolean;

  /** Whether service is allowed. */
  allowService: boolean;

  /** Verbiage to display to the authorizer when reviewing the filing. */
  authorizerVerbiage: string;

  /** Whether case creation is allowed. */
  allowCaseCreation: boolean;

  /** Whether references to existing cases can be used (other than the case being filed into). */
  allowReferencingExistingCase: boolean;

  /** Whether multicase filing is allowed. */
  allowMulticase: boolean;

  /** Whether courtesy copies are allowed. */
  allowCourtesyCopies: boolean;

  /** Whether note to clerk is allowed. */
  allowNoteToClerk: boolean;

  /** Whether adding parties to the current case is allowed. */
  allowAddingPartiesToCase: boolean;

  /** Whether adding attorneys to existing parties is allowed. */
  allowAddingAttorneysToExistingParties: boolean;

  /**
   * The byte size limit for all files included in the filing.
   * @format int64
   */
  totalFileSizeMaxBytes: number;

  /** A description of the filing mode. */
  description?: string | null;

  /** A case level instruction to present to the user. */
  caseInstruction?: Instruction | null;

  /** Rules about the current case. */
  case?: CaseSpec | null;

  /** An instruction to present to the user when working with participants. */
  participantInstruction?: Instruction | null;

  /** The participant specs. */
  participant: ParticipantSpec[];

  /** An instruction to present to the user when working with documents. */
  documentInstruction?: Instruction | null;

  /**
   * Minimum lead documents required
   * @format int32
   */
  minLeadDocumentsRequired: number;

  /**
   * Maximum lead documents allowed
   * @format int32
   */
  maxLeadDocumentsAllowed: number;

  /** Lead document specs. */
  leadDocument: DocumentSpec[];

  /** Supporting document specs. */
  supportingDocument: DocumentSpec[];

  /** A note to the clerk reviewing the filing after submission. */
  noteToClerk?: TextFieldDefinition | null;
}

export enum FilingOption {
  FileAndServe = 'FileAndServe',
  FileOnly = 'FileOnly',
  ServeOnlyPrivate = 'ServeOnlyPrivate',
  ServeOnlyPublic = 'ServeOnlyPublic',
  DocumentUpload = 'DocumentUpload',
}

export interface FilingProfile {
  /** @format uuid */
  courtId: string;

  /** @format uuid */
  id: string;
  caption: string;
  timeZone: string;
  requiresAttestationForCaseAccess: boolean;
  attestationStatementForCaseAccess?: string | null;
  dailyFilingCutoff: string;
  originalPetition?: FilingModeSpec | null;
  subsequent?: FilingModeSpec | null;
  appellate?: FilingModeSpec | null;
  documentAccessCategories: AccessCommonCategoryDomainCategoryValue[];
  identificationCategories: IdentificationCommonCategoryDomainCategoryValue[];
  alias: AliasSpec[];
  addressCategories: AddressCommonCategoryDomainCategoryValue[];
  phoneCategories: PhoneCommonCategoryDomainCategoryValue[];
  emailCategories: EmailCommonCategoryDomainCategoryValue[];
  aliasCategories: AliasCommonCategoryDomainCategoryValue[];
  fileTypes: FileType[];
  fileTypeProfiles: FileTypeProfile[];
  characterProfiles: CharacterProfile[];
  defaultCharacterProfileName: string;
  addressProfiles: AddressProfile[];
  defaultAddressProfileName: string;
  additionalLists: NamedList[];
  efmKey: string;
  classification: DataAttribute[];
}

export interface FilingProfileSummary {
  /** @format uuid */
  id: string;
  caption: string;
  efmKey: string;
  allowsOriginalPetition: boolean;
  allowsSubsequent: boolean;
  allowsAppellate: boolean;
  subsequentFilingsRequireSubCase: boolean;
  timeZone: string;
  requiresAttestationForCaseAccess: boolean;
  attestationStatementForCaseAccess?: string | null;
  classification: DataAttribute[];
}

export interface FilingProfileSummaryEnvelope {
  /** Gets or sets an System.Collections.Generic.IList`1 containing the current page of data. */
  data: FilingProfileSummary[];
}

export interface FilingProfileSummaryItemTree {
  items?: FilingProfileSummary[] | null;
  description?: string | null;
  childLabel?: string | null;
  childDescription?: string | null;
  children?: FilingProfileSummaryItemTree[] | null;
  name: string;
  caption: string;
}

export interface FilingProfileSummaryItemTreeTreeResult {
  /** @format int32 */
  itemCount: number;
  itemLabel?: string | null;
  data?: FilingProfileSummaryItemTree | null;
}

export enum FilingState {
  Unknown = 'Unknown',
  Draft = 'Draft',
  Scheduled = 'Scheduled',
  Submitted = 'Submitted',
  Rejected = 'Rejected',
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  PartiallyAccepted = 'PartiallyAccepted',
}

export interface FilingStatusMetric {
  /** @format int32 */
  count: number;
  efmStatusMetrics: FilingEfmStatusMetric[];
}

export interface FilingUpdate {
  caption: string;

  /** @format date-time */
  scheduledAt: string;

  /** @format uuid */
  authorizerUserId?: string | null;
}

export interface Filter {
  /** The name of the column to filter on */
  column: string;

  /** The operator. */
  operator: Operator;

  /** The value to filter on. If filtering between two values, this is the 'from' value. */
  value1?: any;

  /** When filtering between two values, this is the 'to' value */
  value2?: any;

  /** When filtering on a collection */
  values?: any[] | null;

  /** Additional grouping to use */
  grouping?: any;
}

export interface GetUriResult {
  /** @format uri */
  uri: string;
}

/**
 * This class describes an identifying document of a person.
 */
export interface Identification {
  category: IdentificationCategory;
  identificationKey: string;
  regionKey: string;
}

export interface IdentificationCategory {
  name: string;
  caption: string;
  commonCategory: IdentificationCommonCategory;
}

export interface IdentificationCategorySelectionFieldDefinition {
  /**
   * Minimum number of selections required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of selections allowed.
   * @format int32
   */
  maxAllowed: number;

  /** The reference to the list of options to present. */
  listReference: ProfileListReference;

  /** The default value to present to the user. */
  defaultValue: IdentificationCategory;

  /** Additional fields that appear depending on the selected values. */
  selectionDependentFields: SelectionDependentFields[];
  readOnly: boolean;
  visible: boolean;
}

export enum IdentificationCommonCategory {
  None = 'None',
  Other = 'Other',
  BarNumber = 'BarNumber',
  TaxId = 'TaxId',
  DrivingLicense = 'DrivingLicense',
  PassportNumber = 'PassportNumber',
  BadgeNumber = 'BadgeNumber',
  MedicalLicense = 'MedicalLicense',
  StateLicense = 'StateLicense',
  Ssn = 'Ssn',
}

export interface IdentificationCommonCategoryDomainCategoryValue {
  commonCategory: IdentificationCommonCategory;

  /**
   * Sequence indicates the order in which to display value to the user - sequence numbers do not have to be contiguous (sequences can have gaps)
   * @format int32
   */
  sequence: number;
  name: string;
  caption: string;
  efmKey: string;
}

export interface IdentificationSpec {
  /**
   * Minimum number of identifications required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of identifications allowed.
   * @format int32
   */
  maxAllowed: number;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Identification.IdentificationKey" />. */
  identificationText: TextFieldDefinition;

  /** The top-level area division in the country, such as state, district, province, island, region. */
  region: StringSelectionFieldDefinition;

  /** Field definition for the category on an entity. */
  category?: IdentificationCategorySelectionFieldDefinition | null;
  description?: string | null;
  additionalFields?: AdditionalFieldSpec[] | null;
}

/**
 * DTO model to support requests to import documents from temp area to docstore.
 */
export interface ImportDocumentRequest {
  /**
   * Source uri from the temporary storage area.
   * @format uri
   */
  sourceUri: string;

  /** Original name of the file. */
  originalFileName: string;

  /** File display name. */
  displayName: string;

  /** File content type. */
  contentType?: string | null;
}

export interface Instruction {
  caption: string;
  description: string;
  link?: string | null;
}

export interface InstructionDefinition {
  description: string;
  link: string;
}

export interface NamedList {
  name: string;
  items: NamedListItem[];
}

export interface NamedListItem {
  name: string;
  caption: string;
  efmKey: string;
}

export interface NewContact {
  contactType: ContactType;

  /** Aliases the contact goes by. */
  aliases: ContactAlias[];

  /** Lightweight reference to a contact. */
  parentOrganization?: ContactReference | null;
  caption: string;

  /** A contact person. */
  person?: ContactPerson | null;

  /** A contact organization. It may have a person contact as a primary contact. */
  organization?: ContactOrganization | null;
  addresses: Address[];
  emails: EmailAddress[];
  identifications: Identification[];
  phones: Phone[];
}

export interface NewFiling {
  /** @format uuid */
  courtId: string;

  /** @format uuid */
  profileId: string;
  mode: FilingMode;

  /** The title of the filing (typically labeled as the Case Name of the main case) */
  title: string;

  /** The caller-designated caption of the case, overrides all default assignments. */
  caption?: string | null;

  /** Optional.  The list of existing case(s) for a subsequent filing. */
  cases: NewFilingCase[];
}

/**
 * Request object for subf multicase cases.
 */
export interface NewFilingCase {
  /** The short form title of the case. */
  title: string;

  /** The docket style case numbering. */
  caption: string;

  /** The tracking/primary identifier provided by the EFM. */
  efmKey: string;
}

export interface NumberFieldDefinition {
  /** Indicates that a value is required. */
  required: boolean;

  /**
   * The number of decimals to allow on the field. For example, 3 allows 1234.567 but not 1234.5678.
   * @format int32
   */
  allowedDecimals: number;

  /**
   * Minimum value allowed in the field.
   * @format double
   */
  minValue?: number | null;

  /**
   * Maximum value allowed in the field.
   * @format double
   */
  maxValue?: number | null;

  /**
   * Default value for the field.
   * @format double
   */
  defaultValue?: number | null;
  readOnly: boolean;
  visible: boolean;
}

export interface Operation {
  value?: any;
  path?: string | null;
  op?: string | null;
  from?: string | null;
}

export enum Operator {
  Unknown = 'Unknown',
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqual',
  Between = 'Between',
  Contains = 'Contains',
  NotBlank = 'NotBlank',
  StartsWith = 'StartsWith',
  Exclude = 'Exclude',
  Include = 'Include',
}

export interface Option {
  /** The name of the option. */
  name: string;

  /** An optional value that modifies the option's behavior. */
  value?: string | null;
}

/**
 * Spec for: <inheritdoc cref="T:FileAndServe.Efsp.Shared.Models.ContactOrganization" />
 */
export interface OrganizationSpec {
  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.OrganizationBase.Title" />. */
  title: TextFieldDefinition;
}

export interface ParticipantCategory {
  name: string;
  caption: string;
  commonCategory: ParticipantCommonCategory;
}

export enum ParticipantCommonCategory {
  None = 'None',
  Other = 'Other',
  InitiatingParty = 'InitiatingParty',
  AdditionalParty = 'AdditionalParty',
  Attorney = 'Attorney',
}

export interface ParticipantCommonCategoryDomainCategoryValue {
  commonCategory: ParticipantCommonCategory;

  /**
   * Sequence indicates the order in which to display value to the user - sequence numbers do not have to be contiguous (sequences can have gaps)
   * @format int32
   */
  sequence: number;
  name: string;
  caption: string;
  efmKey: string;
}

export interface ParticipantFieldDefinition {
  /** Whether the field allows participants that are new to the case. */
  allowNewParticipants: boolean;

  /** Whether the field allows participants that are already in the case. */
  allowExistingParticipants: boolean;

  /** Participants of any of the listed categories are allowed. */
  allowedParticipantCategoriesList: ProfileListReference;

  /**
   * Minimum number of participants required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of participants allowed.
   * @format int32
   */
  maxAllowed: number;

  /** Additional fields. */
  additionalFields?: AdditionalFieldSpec[] | null;
  readOnly: boolean;
  visible: boolean;
}

export interface ParticipantFieldSpec {
  participantFieldDefinition: ParticipantFieldDefinition;
  caption: string;
}

/**
 * Spec describing a case participant and their relationship to a case.
 */
export interface ParticipantSpec {
  participantCategory: ParticipantCommonCategoryDomainCategoryValue;

  /**
   * Minimum amount of participants of this category that the filing must contain.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum amount of participants of this category that a filing may contain.
   * @format int32
   */
  maxAllowed: number;

  /** 'True' allows participants of this category to be persons. */
  allowPerson: boolean;

  /** 'True' allows participants of this category to be organizations. */
  allowOrganization: boolean;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.CaseParty.ParticipantSubCategoryNames" /> */
  subCategory?: StringSelectionFieldDefinition | null;

  /** For case participants, spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Person" /> */
  person?: PersonSpec | null;

  /** For case participants, spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Organization" /> */
  organization?: OrganizationSpec | null;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Identifications" /> */
  identification?: IdentificationSpec | null;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Addresses" /> */
  address?: AddressSpec | null;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Phones" /> */
  phone?: PhoneSpec | null;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.ContactBase.Emails" /> */
  email?: EmailAddressSpec | null;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Request.RequestParticipant.Aliases" /> */
  alias?: AliasFieldDefinition | null;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.CaseParty.Representation" /> */
  representation?: RepresentationSpec | null;

  /** Spec for: <inheritdoc cref="!:RequestContact.ParentOrganizationCaption" /> */
  parentOrganizationCaption?: TextFieldDefinition | null;
  description?: string | null;
  additionalFields?: AdditionalFieldSpec[] | null;
}

/**
 * Spec for: <inheritdoc cref="T:FileAndServe.Efsp.Shared.Models.PersonalName" />
 */
export interface PersonNameSpec {
  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.PersonalName.Prefix" /> */
  prefix?: TextFieldDefinition | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.PersonalName.GivenName" /> */
  givenName?: TextFieldDefinition | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.PersonalName.MiddleName" /> */
  middleName?: TextFieldDefinition | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.PersonalName.SurName" /> */
  surName?: TextFieldDefinition | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.PersonalName.Suffix" /> */
  suffix?: TextFieldDefinition | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.PersonalName.FullName" /> */
  fullName?: TextFieldDefinition | null;
}

/**
 * Spec for: <inheritdoc cref="T:FileAndServe.Efsp.Shared.Models.Base.PersonBase" />
 */
export interface PersonSpec {
  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.PersonBase.PersonalName" /> */
  personalName?: PersonNameSpec | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.PersonBase.DateOfDeath" /> */
  dateOfDeath?: DateTimeFieldDefinition | null;

  /** Field definition for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.PersonBase.DateOfBirth" /> */
  dateOfBirth?: DateTimeFieldDefinition | null;
}

/**
 * A person's name.
 */
export interface PersonalName {
  /** The given name (first name). */
  givenName?: string | null;

  /** The middle name. */
  middleName?: string | null;

  /** The surname (last name). */
  surName?: string | null;

  /** The name prefix (e.g. "Dr.", "Mrs.", "Rev."). */
  prefix?: string | null;

  /** The name suffix (e.g. "Dr.", "Mrs.", "Rev."). */
  suffix?: string | null;

  /** The full name (FileAndServe.Efsp.Shared.Models.PersonalName.GivenName, FileAndServe.Efsp.Shared.Models.PersonalName.MiddleName, FileAndServe.Efsp.Shared.Models.PersonalName.SurName. */
  fullName: string;
}

export interface Phone {
  category?: PhoneCategory | null;

  /** A user-assigned label (e.g. "My favorite", "Main"). */
  caption: string;

  /** The country code portion of the phone number. */
  countryKey?: string | null;

  /** A full local (within the local country) number. */
  fullNumber: string;

  /** An additional sequence of numbers to be entered after a call connects to be directed to the appropriate place. */
  suffix?: string | null;
}

export interface PhoneCategory {
  name: string;
  caption: string;
  commonCategory: PhoneCommonCategory;
}

export interface PhoneCategorySelectionFieldDefinition {
  /**
   * Minimum number of selections required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of selections allowed.
   * @format int32
   */
  maxAllowed: number;

  /** The reference to the list of options to present. */
  listReference: ProfileListReference;

  /** The default value to present to the user. */
  defaultValue: PhoneCategory;

  /** Additional fields that appear depending on the selected values. */
  selectionDependentFields: SelectionDependentFields[];
  readOnly: boolean;
  visible: boolean;
}

export enum PhoneCommonCategory {
  None = 'None',
  Other = 'Other',
  Home = 'Home',
  Business = 'Business',
  Fax = 'Fax',
}

export interface PhoneCommonCategoryDomainCategoryValue {
  commonCategory: PhoneCommonCategory;

  /**
   * Sequence indicates the order in which to display value to the user - sequence numbers do not have to be contiguous (sequences can have gaps)
   * @format int32
   */
  sequence: number;
  name: string;
  caption: string;
  efmKey: string;
}

export interface PhoneSpec {
  /**
   * Minimum number of phones required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of phones allowed.
   * @format int32
   */
  maxAllowed: number;

  /** Spec for: <inheritdoc cref="P:FileAndServe.Efsp.Shared.Models.Base.PhoneBase.FullNumber" /> */
  fullNumber: TextFieldDefinition;

  /** Field definition for the category on an entity. */
  category?: PhoneCategorySelectionFieldDefinition | null;
  description?: string | null;
  additionalFields?: AdditionalFieldSpec[] | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;

  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
}

export interface ProfileListReference {
  list: ProfileLists;
  additionalListName: string;
}

export enum ProfileLists {
  Countries = 'Countries',
  AdditionalList = 'AdditionalList',
  AddressCategories = 'AddressCategories',
  AliasCategories = 'AliasCategories',
  CaseAdditionalList = 'CaseAdditionalList',
  EmailCategories = 'EmailCategories',
  IdentificationCategories = 'IdentificationCategories',
  PhoneCategories = 'PhoneCategories',
}

export interface Query {
  /**
   * The number of rows to skip.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  skip: number;

  /**
   * The max number of records to return.
   * @format int32
   * @min 1
   * @max 250
   */
  limit: number;

  /** Details of the sort column and order */
  sort: Sort[];

  /** Optional.  The filter criteria used to constrain the results. */
  filters: Filter[];

  /** Optional. Usage is up to each query endpoint. */
  options: Option[];

  /** True to request an exact count of the total number of results available (may be expensive and not honored) */
  exactTotal: boolean;
}

export enum RenderingStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Ready = 'Ready',
  Error = 'Error',
  FailedChecks = 'FailedChecks',
}

/**
 * Spec describing a participant category's representation when not self-representing.
 */
export interface RepresentationSpec {
  /**
   * Minimum representation required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum representation allowed.
   * @format int32
   */
  maxAllowed: number;
  description?: string | null;
  additionalFields?: AdditionalFieldSpec[] | null;
}

export interface RequestCase {
  /**
   * The Id for the case. Can be left null for opf.
   * @format uuid
   */
  caseId?: string | null;

  /** The case's title. (e.g., Murray vs Lee) */
  title?: string | null;

  /** The case's caption. */
  caption?: string | null;

  /** The case's external efm key. */
  efmKey?: string | null;

  /** The amount the dispute is valued at. */
  amountInControversy?: CurrencyValue | null;

  /** Whether a case is complex (e.g., class action). */
  isComplexCase: boolean;

  /** The address at which the incident central to the case occurred. */
  incidentAddress?: Address | null;

  /** Values for additional fields as described by FileAndServe.Efsp.Shared.Models.Profiles.Fields.AdditionalFieldSpec. */
  additionalFieldValues?: AdditionalFieldValue[] | null;
}

export interface RequestContact {
  /** Readable text identifying the contact. E.g. "Green, Lisa". */
  caption: string;

  /** The type (person, organization, etc) of contact. */
  contactType: ContactType;

  /** Details specific to a Person type contact and its variants. */
  person?: ContactPerson | null;

  /** Details specific to an Organization type contact. */
  organization?: RequestContactOrganization | null;

  /** The location addresses. */
  addresses: Address[];

  /** The email addresses. */
  emails: EmailAddress[];

  /** Form of personal identification. */
  identifications: Identification[];

  /** The phone numbers. */
  phones: Phone[];
}

/**
 * An organization in a filing.
 */
export interface RequestContactOrganization {
  /** The legal name of the organization */
  title: string;

  /** A short name for the organization */
  caption: string;
}

export interface RequestDocument {
  /**
   * The document's Id within its document collection.
   * @format uuid
   */
  id?: string | null;

  /** An identifier for the document, unique inside a case. */
  name: string;

  /** The user-provided title for the document. */
  title: string;

  /** The user provided filename to use for the document. */
  fileName: string;

  /** The EfmKey for the document. */
  efmKey?: string | null;

  /** The category of the document. */
  category?: DocumentCategory | null;

  /** The access category (Public, Private, etc) of the document. */
  accessCategoryName?: string | null;

  /** When true, the document is a lead document. When false, the document is a supporting document. */
  isLeadDocument: boolean;

  /** The relationship between the document and individual cases. */
  cases?: RequestDocumentCase[] | null;
}

export interface RequestDocumentCase {
  /**
   * The Id for the case. Can be left null for opf.
   * @format uuid
   */
  caseId?: string | null;

  /** The participants filing the document. */
  filedBy?: RequestDocumentParticipant[] | null;

  /** The participants referenced in the document. */
  filedAsTo?: RequestDocumentParticipant[] | null;

  /** References to documents outside of the filing. */
  linkedDocuments?: any[] | null;

  /** Values for additional fields as described by FileAndServe.Efsp.Shared.Models.Profiles.Fields.AdditionalFieldSpec. */
  additionalFieldValues?: AdditionalFieldValue[] | null;
}

export interface RequestDocumentParticipant {
  participantName: string;
  additionalFieldValues?: AdditionalFieldValue[] | null;
}

export interface RequestParticipant {
  /** The (optional) reference to the contact that corresponds to the participant. */
  linkedContact?: ContactReference | null;

  /** An identifier for the participant, unique inside a case. */
  name: string;

  /** The user-entered caption of the organization the participant belongs to, e.g. for an external attorney, the name of their firm. */
  parentOrganizationCaption?: string | null;

  /** Alternate identities of the participant. */
  aliases?: RequestParticipantAlias[] | null;

  /** Readable text identifying the contact. E.g. "Green, Lisa". */
  caption: string;

  /** The type (person, organization, etc) of contact. */
  contactType: ContactType;

  /** Details specific to a Person type contact and its variants. */
  person?: ContactPerson | null;

  /** Details specific to an Organization type contact. */
  organization?: RequestContactOrganization | null;

  /** The location addresses. */
  addresses: Address[];

  /** The email addresses. */
  emails: EmailAddress[];

  /** Form of personal identification. */
  identifications: Identification[];

  /** The phone numbers. */
  phones: Phone[];
}

export interface RequestParticipantAlias {
  /** The alias category, e.g. AKA, Formerly Doing Business As, etc. */
  category: AliasCategory;

  /** Readable text identifying the contact. E.g. "Green, Lisa". */
  caption: string;

  /** The type (person, organization, etc) of contact. */
  contactType: ContactType;

  /** Details specific to a Person type contact and its variants. */
  person?: ContactPerson | null;

  /** Details specific to an Organization type contact. */
  organization?: RequestContactOrganization | null;

  /** The location addresses. */
  addresses: Address[];

  /** The email addresses. */
  emails: EmailAddress[];

  /** Form of personal identification. */
  identifications: Identification[];

  /** The phone numbers. */
  phones: Phone[];
}

export interface RequestParticipantRepresentation {
  /** Readable text identifying the representing participant. E.g. "Green, Lisa". */
  caption: string;

  /** The name of the representing participant. */
  participantName: string;

  /** The EfmKey for the representation. */
  efmKey?: string | null;

  /** The participant category of the representation. */
  participantCategory?: ParticipantCategory | null;

  /** Values for additional fields as described by FileAndServe.Efsp.Shared.Models.Profiles.Fields.AdditionalFieldSpec. */
  additionalFieldValues?: AdditionalFieldValue[] | null;
}

export interface SearchFieldDefinition {
  searchTooltip?: string | null;

  /** Used by the Efm Adapter to understand the kind of search it needs to do. */
  searchName: string;
  required: boolean;
  readOnly: boolean;
  visible: boolean;
}

export interface SearchResultItem {
  name: string;
  caption: string;
  efmKey?: string | null;
}

export interface SearchResultItemGridResult {
  /**
   * The (possibly approximate) total number of results.
   * @format int32
   */
  total: number;

  /**
   * The number of results being returned
   * @format int32
   */
  count: number;

  /**
   * The maximum number of results requested
   * @format int32
   */
  limit: number;

  /**
   * The number of results skipped.
   * @format int32
   */
  skip: number;

  /** True of the total is an exact number of total results, false if it is an approximation. */
  exactTotal: boolean;

  /** Optional. The filter expression used to narrow the results */
  filters?: Filter[] | null;

  /** Details of the sort column and order */
  sort?: Sort[] | null;

  /** Gets or sets an System.Collections.Generic.IList`1 containing the current page of data. */
  data: SearchResultItem[];
}

export interface Selection {
  name: string;
  caption: string;
}

export interface SelectionDependentFields {
  selectedName: string;
  additionalFields?: AdditionalFieldSpec[] | null;
}

export interface SelectionEnvelope {
  /** Gets or sets an System.Collections.Generic.IList`1 containing the current page of data. */
  data: Selection[];
}

export enum Severity {
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error',
  Unknown = 'Unknown',
}

export interface Sort {
  /** The name of the column to sort on */
  column: string;

  /** The direction in which to sort */
  direction: Direction;
}

export interface StringSelectionFieldDefinition {
  /**
   * Minimum number of selections required.
   * @format int32
   */
  minRequired: number;

  /**
   * Maximum number of selections allowed.
   * @format int32
   */
  maxAllowed: number;

  /** The reference to the list of options to present. */
  listReference: ProfileListReference;

  /** The default value to present to the user. */
  defaultValue: string;

  /** Additional fields that appear depending on the selected values. */
  selectionDependentFields: SelectionDependentFields[];
  readOnly: boolean;
  visible: boolean;
}

export interface TextFieldDefinition {
  required: boolean;

  /** @format int32 */
  minLength: number;

  /** @format int32 */
  maxLength: number;
  characterProfileName?: string | null;
  readOnly: boolean;
  visible: boolean;
}

export interface TimeFieldDefinition {
  /** Indicates that a value is required. */
  required: boolean;

  /** @format date-span */
  minTime: string;

  /** @format date-span */
  maxTime: string;

  /** The timezone applicable to the field. */
  timeZone: string;
  readOnly: boolean;
  visible: boolean;
}

export interface UploadDocumentRequest {
  /**
   * Source uri from the temporary storage area.
   * @format uri
   */
  sourceUri: string;
}

export interface UserData {
  username: string;

  /** @format date-time */
  lastLogin: string;
}

export interface ValidationDetail {
  /** Unique code for validation failure, to be used by service reps */
  code: string;

  /** Name of the field being validated. */
  field?: string | null;

  /** Severity of the validation event */
  severity: Severity;

  /** Caption of the validation event */
  caption: string;

  /** Description of the validation event */
  description?: string | null;
}

/**
 * Response object for validation calls.
 */
export interface ValidationResult {
  /**
   * Number of errors.
   * @format int32
   */
  errors: number;

  /**
   * Number of warnings.
   * @format int32
   */
  warnings: number;

  /**
   * Number of infos.
   * @format int32
   */
  information: number;

  /** Validation results grouped by path to the validated object. */
  validations: Record<string, ValidationDetail[]>;
}

export interface WriteDocumentRequest {
  /** Original name of the file. */
  originalFileName: string;

  /** File display name. */
  displayName: string;

  /** File content type. */
  contentType?: string | null;
}
