import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface IWarningStateService {
  warningMessage$: Observable<string>;
  showWarning(message: string): void;
}

@Injectable({
  providedIn: 'root'
})
export class WarningStateService implements IWarningStateService{

  constructor() { }

  private warning$$ = new Subject<string>();
  warningMessage$: Observable<string> = this.warning$$.asObservable();


  showWarning(message: string) {
    this.warning$$.next(message);
  }
}
